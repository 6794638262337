.menu-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  bottom: 4rem;
  left: 1rem;
  overflow: scroll;
  background: #EFEFEFDD;
  margin: auto;
  max-width: 44rem;
}
