.container {
  width: 100%;
  height: calc(100vh - 6rem);
  position: relative;
  /* background: midnightblue; */
}

.page {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.page img {
  max-height: 100%;
  max-width: 100%;
  margin: 1rem auto;
  object-fit: scale-down;
  display: block;
  box-sizing: border-box;
  border: 1px #CCC solid;
}
