.menu {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3rem;
  background: gray;
  display: flex;
}

.menu .pageButton {
  min-height: 3rem;
  width: 3rem;
  min-width: 2rem;
}
